import "./App.css";

import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import MainScreen from "./components/Main/MainScreen";
import { Toaster } from "react-hot-toast";
import { HOME_DIR } from "./utills/constants";
import VConsole from 'vconsole';

import liff from "@line/liff";

function App() {

  new VConsole();


  if (liff) {
    return (
      <Router basename={`${HOME_DIR}`}>
        <Toaster
          containerStyle={{
            top: 30,
            zIndex: 9999,
          }}
        />

        <Routes>
          <Route exact path="/" element={<MainScreen />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
