export const VERSION_APP = '1.0.1';
export const HOME_DIR = '/';

export const ENV = {
    LOCAL: {
        LIFF_ID: '2004188246-ewAZXoJl',
    },
    PROD: {
        LIFF_ID: '2004188246-DQ85m0yZ',
    }
}

export const CURRENT_ENV = ENV.PROD;

export const HEADER = {
    "headers": {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': '*',
        'Accept': '*/*',
        "X-Api-Key": "kSGdxruxpjHEC6eaQ3qOgWMZCZQopfBc",
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    }
}

export const TOAST_DURATION = 2000;
