import React from 'react';

import liff from "@line/liff";

import { CURRENT_ENV } from '../../utills/constants';

function MainScreen() {

    // useEffect(() => {
    // liff.ready.then(() => {

    liff
        .init({
            liffId: CURRENT_ENV.LIFF_ID,
        })
        .then(async () => {
            console.log("isLoggedIn : ", liff.isLoggedIn());
            if (liff.isLoggedIn()) {
                liff
                    .getProfile()
                    .then((profile) => {
                        console.log("%cPROFILE LINEOA : ", "color: green", profile);

                        liff
                            .scanCodeV2()
                            .then((result) => {
                                console.log(result.value)

                                const regex = /series=([^]+)/;
                                const match = result.value.match(regex);

                                if (match) {
                                    const seriesNumber = match[1];

                                    liff.sendMessages([
                                        {
                                            type: "text",
                                            text: `${seriesNumber}`,
                                        },
                                    ]).then((_) => {
                                        liff.closeWindow();
                                    })
                                } else {
                                    const regex2 = /sku=([^]+)/;
                                    const match2 = result.value.match(regex2);
                                    if (match2) {
                                        const skuNumber = match2[1];

                                        liff.sendMessages([
                                            {
                                                type: "text",
                                                text: `${skuNumber}`,
                                            },
                                        ]).then((_) => {
                                            liff.closeWindow();
                                        })
                                    } else {

                                        liff.sendMessages([
                                            {
                                                type: "text",
                                                text: `QRCode ข้อมูลไม่ตรงกับที่ค้นหา`,
                                            },
                                        ]).then((_) => {
                                            liff.closeWindow();
                                        })
                                    }
                                }

                            })
                            .catch((error) => {
                                liff.closeWindow();
                                console.log("error ScanQRCode: ", error);
                            });

                    })
                    .catch((err) => {
                        console.log("%cERROR LINEOA DETAIL", "color: red", err);
                    });
            } else {
                liff.login();
            }
        })
        .catch((err) => {
            console.log("%cERROR LINEOA HEADER", err.code, err.message);
        });
    // });

    // }, 300);

    // }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>

        </div>
    )
}

export default MainScreen;